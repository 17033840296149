import styled from 'styled-components'

export default styled.section`
    .wrapperTittleEnvironmental {
        display: flex;
        justify-content: center;
        .backgroundContentEnvironmental {
            text-align: center;
            padding: 10px;
            background: #393737;
            color: #f0ebeb;
            width: 60%;
        }
    }

    .initialEnvironmental {
        display: flex;
        justify-content: space-between;
        .contentEnvironmental {
            flex-basis: 50%;
            align-self: center;
        }

        .imgEnvironmental {
            flex-basis: 45%;

            img {
                width: 100%;
                height: 380px;
                box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
            }
        }
    }

    .reverse-logistic-image {
        display: flex;
        justify-content: center;

        margin: auto;
        box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
        .videoEnvironmental {
            width: 80%;
            height: 450px;
        }
    }

    .indicativesEnvironmental {
        display: flex;
        justify-content: space-between;

        .itemDarkEnvironmental {
            background-color: #393737;
        }

        .itemLightEnvironmental {
            background-color: #c4c4c4;
        }

        .iconIndicativeEnvironmental {
            padding: 0 0 20px;
            font-size: 80px;
        }

        .itemEnv {
            text-align: center;
            padding: 30px;
        }
    }

    @media only screen and (max-width: 798px) {
        .wrapperTittleEnvironmental .backgroundContentEnvironmental {
            width: 100%;
        }

        .reverse-logistic-image .videoEnvironmental {
            width: 100%;
        }

        .initialEnvironmental {
            flex-direction: column;
            .contentEnvironmental {
                order: 2;
                flex-basis: 100%;
            }

            .imgEnvironmental {
                margin-bottom: 30px;
                flex-basis: 100%;
                img {
                    height: 250px;
                    width: 100%;
                }
            }
        }

        .indicativesEnvironmental {
            flex-direction: column;
            align-items: center;
            .itemEnv {
                max-width: 350px;
            }
        }
    }
`
