import styled from 'styled-components'
import steelBanner from '../../assets/images/background/steel-background.jpg'

export const DividerBanner = styled.div`
    background: url(${steelBanner});

    position: relative;

    height: auto;
    background-position: top center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;

    .overlay {
        opacity: 0.45;
    }

    .bannerContent {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        padding: 32px;
        line-height: 32px;
        .bannerTitle {
            display: flex;
            align-items: center;
            flex-direction: row;

            span {
                width: 12px;
            }

            .spanR {
                transform: rotate(270deg);
                margin-left: 20px;
            }

            .spanL {
                margin-right: 20px;
                transform: rotate(90deg);
            }
        }
    }

    .highlight-title {
        color: #db3925;
        font-weight: bold;
    }
`
