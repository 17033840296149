import React from 'react'

import { DividerBanner } from './styles'

export default ({ title, subtitle }) => {
    return (
        <DividerBanner>
            <div class="overlay" />

            <div className="bannerContent">
                <div className="bannerTitle">
                    <h2 className="font-white font-title">{title}</h2>
                </div>
                <div className="bannerSubtitle">
                    <p className="font-white font-subtitle">{subtitle}</p>
                </div>
            </div>
        </DividerBanner>
    )
}
