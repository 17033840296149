import React from 'react'

import Layout from '../layout'
import SEO from '../utils/seo'
import ContainerEnvironmental from '../assets/styles/pages/environmental'
import BannerDivider from '../components/bannerDivider'

import bioImg from '../assets/images/environmental/bio.jpg'
import logisticGif from '../assets/videos/logistica-reversa.gif'
import Divider from '../components/Divider'

const EnvironmentalPage = () => (
    <Layout>
        <SEO title="Meio Ambiente" />
        <BannerDivider title="Meio Ambiente" subtitle="Respeito" />
        <ContainerEnvironmental className="col-10">
            <div className="wrapperTittleEnvironmental mt-3px">
                <div className="backgroundContentEnvironmental">
                    <h1 className="font-subtitle">Responsabilidade social</h1>
                    <p className="mt-1">Fazendo diferença, por um mundo melhor!</p>
                </div>
            </div>

            <div className="initialEnvironmental mt-3px">
                <div className="contentEnvironmental mb-2">
                    <p className="font-paragraph">
                        Visamos fortalecer a relação com nossos clientes e ampliar nossa atuação em
                        Minas Gerais, pensamos também no socioambiental.
                    </p>
                    <p className="font-paragraph mt-2">
                        Com esse compromisso, auxiliamos nossos clientes no descarte correto dos
                        resíduos das codificadoras e enviamos para destinação apropriada, buscando
                        garantir a excelência no processo de descarte de modo a diminuir ao máximo
                        os impactos ambientais.
                    </p>
                </div>

                <div className="imgEnvironmental">
                    <img src={bioImg} alt="Preservando o meio ambiente - Minas Coding" />
                </div>
            </div>

            <Divider size={8} orientation="horizontal" />

            <img
                src={logisticGif}
                alt="Logistica Reversa - Minas Coding"
                className="reverse-logistic-image"
            />

            <Divider size={8} orientation="horizontal" />
        </ContainerEnvironmental>
    </Layout>
)

export default EnvironmentalPage
